//import React, { useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";


import child1 from "../../assets/images/amis/paints/childrens/child-(1).jpg";
import child2 from "../../assets/images/amis/paints/childrens/child-(2).JPG";
import child3 from "../../assets/images/amis/paints/childrens/child-(3).jpg";
import child4 from "../../assets/images/amis/paints/childrens/child-(4).jpg";
import child5 from "../../assets/images/amis/paints/childrens/child-(5).jpg";

import womens1 from "../../assets/images/amis/paints/womens/women-(1).jpg";
import womens2 from "../../assets/images/amis/paints/womens/women-(2).jpg";
import womens3 from "../../assets/images/amis/paints/womens/women-(3).jpg";
import womens4 from "../../assets/images/amis/paints/womens/women-(4).jpg";
import womens5 from "../../assets/images/amis/paints/womens/women-(5).jpg";

import society1 from "../../assets/images/amis/paints/society/society-(1).jpg";
import society2 from "../../assets/images/amis/paints/society/society-(2).jpg";
import society3 from "../../assets/images/amis/paints/society/society-(3).jpg";
import society4 from "../../assets/images/amis/paints/society/society-(4).jpg";
import society5 from "../../assets/images/amis/paints/society/society-(5).jpg";

import banner_1 from "../../assets/images/banner_1.png";
import banner_2 from "../../assets/images/Banner_2.png";
import banner_3 from "../../assets/images/Banner_3.png";
import vision_mission_banner from "../../assets/images/vision_mission_banner.png";

import home_banner1 from "../../assets/images/amis/home_banner/1.jpg";
import home_banner2 from "../../assets/images/amis/home_banner/2.jpg";
import home_banner3 from "../../assets/images/amis/home_banner/3.jpg";
import home_banner4 from "../../assets/images/amis/home_banner/4.jpg";
import home_banner4_1 from "../../assets/images/amis/home_banner/4-1.jpg";
import home_banner4_2 from "../../assets/images/amis/home_banner/4-2.jpg";
import home_banner5 from "../../assets/images/amis/home_banner/5.jpg";
import home_banner5_ from "../../assets/images/amis/home_banner/5_.jpg";
import home_banner6 from "../../assets/images/amis/home_banner/6.jpg";
import home_banner8 from "../../assets/images/amis/home_banner/8.jpg";
import home_banner9 from "../../assets/images/amis/home_banner/9.jpg";
import home_banner10 from "../../assets/images/amis/home_banner/10.jpg";
import home_banner11 from "../../assets/images/amis/home_banner/11.jpg";
import home_banner12 from "../../assets/images/amis/home_banner/12.jpg";
import home_banner13 from "../../assets/images/amis/home_banner/13.jpg";
import home_banner14 from "../../assets/images/amis/home_banner/14.jpg";
import home_banner15 from "../../assets/images/amis/home_banner/15.jpg";
import home_banner16 from "../../assets/images/amis/home_banner/16.jpg";
import home_banner17 from "../../assets/images/amis/home_banner/17.jpg";
import home_banner18_1 from "../../assets/images/amis/home_banner/18(1).JPG";
import home_banner18 from "../../assets/images/amis/home_banner/18.jpg";
import home_banner19 from "../../assets/images/amis/home_banner/19.jpg";

import home_banner20_1 from "../../assets/images/amis/home_banner/20(1).jpg";
import home_banner20 from "../../assets/images/amis/home_banner/20.JPG";
import home_banner21 from "../../assets/images/amis/home_banner/21.jpg";
import home_banner22 from "../../assets/images/amis/home_banner/22.jpg";
import home_banner24 from "../../assets/images/amis/home_banner/24.jpg";
import home_banner25 from "../../assets/images/amis/home_banner/25.jpg";
import home_banner26 from "../../assets/images/amis/home_banner/26.jpg";
import home_banner27 from "../../assets/images/amis/home_banner/27.jpg";
import home_banner28 from "../../assets/images/amis/home_banner/28.JPG";
import home_banner29 from "../../assets/images/amis/home_banner/29.JPG";

import home_banner30 from "../../assets/images/amis/home_banner/30.JPG";
import home_banner31 from "../../assets/images/amis/home_banner/31.jpg";
import home_banner32 from "../../assets/images/amis/home_banner/32.jpg";
import home_banner33 from "../../assets/images/amis/home_banner/33.jpg";
import home_banner34 from "../../assets/images/amis/home_banner/34.jpg";
import home_banner35 from "../../assets/images/amis/home_banner/35.jpg";
import home_banner36 from "../../assets/images/amis/home_banner/36.jpg";
import home_banner37 from "../../assets/images/amis/home_banner/37.jpg";
import home_banner38 from "../../assets/images/amis/home_banner/38.jpg";


import english_paint1 from "../../assets/images/amis/english/1.png";
import english_paint2 from "../../assets/images/amis/english/2.png";
import english_paint3 from "../../assets/images/amis/english/3.png";
import english_paint4 from "../../assets/images/amis/english/4.png";
import english_paint5 from "../../assets/images/amis/english/5.png";
import english_paint6 from "../../assets/images/amis/english/6.png";
import english_paint7 from "../../assets/images/amis/english/7.png";
import english_paint8 from "../../assets/images/amis/english/8.png";
import english_paint9 from "../../assets/images/amis/english/9.png";
import english_paint10 from "../../assets/images/amis/english/10.png";
import english_paint11 from "../../assets/images/amis/english/11.png";
import english_paint12 from "../../assets/images/amis/english/12.png";
import english_paint13 from "../../assets/images/amis/english/13.png";
import english_paint14 from "../../assets/images/amis/english/14.png";
import english_paint15 from "../../assets/images/amis/english/15.png";
import english_paint16 from "../../assets/images/amis/english/16.png";
import english_paint17 from "../../assets/images/amis/english/17.png";
import english_paint18 from "../../assets/images/amis/english/18.png";
import english_paint19 from "../../assets/images/amis/english/19.png";
import english_paint20 from "../../assets/images/amis/english/20.png";
import english_paint21 from "../../assets/images/amis/english/21.png";
import english_paint22 from "../../assets/images/amis/english/22.png";
import english_paint23 from "../../assets/images/amis/english/23.png";
import english_paint24 from "../../assets/images/amis/english/24.png";
import english_paint25 from "../../assets/images/amis/english/25.png";
import english_paint26 from "../../assets/images/amis/english/26.png";
import english_paint27 from "../../assets/images/amis/english/27.png";
import english_paint28 from "../../assets/images/amis/english/28.png";
import english_paint29 from "../../assets/images/amis/english/29.png";
import english_paint30 from "../../assets/images/amis/english/30.png";
import english_paint31 from "../../assets/images/amis/english/31.png";
import english_paint32 from "../../assets/images/amis/english/32.png";
import english_paint33 from "../../assets/images/amis/english/33.png";
import english_paint34 from "../../assets/images/amis/english/34.png";
import english_paint35 from "../../assets/images/amis/english/35.png";
import english_paint36 from "../../assets/images/amis/english/36.png";
import english_paint37 from "../../assets/images/amis/english/37.png";
import english_paint38 from "../../assets/images/amis/english/38.png";
import english_paint39 from "../../assets/images/amis/english/39.png";
import english_paint40 from "../../assets/images/amis/english/40.png";
import english_paint41 from "../../assets/images/amis/english/41.png";
import english_paint42 from "../../assets/images/amis/english/42.png";
 

import hindi_img1 from "../../assets/images/amis/hindi/Photo 1.jpg";
import hindi_img2 from "../../assets/images/amis/hindi/Photo 2.jpg";
import hindi_img3 from "../../assets/images/amis/hindi/Photo 3.jpg";
import hindi_img4 from "../../assets/images/amis/hindi/Photo 4.jpg";
import hindi_img5 from "../../assets/images/amis/hindi/Photo 5.jpg";
import hindi_img6 from "../../assets/images/amis/hindi/Photo 6.jpg";
import hindi_img7 from "../../assets/images/amis/hindi/Photo 7.jpg";
import hindi_img8 from "../../assets/images/amis/hindi/Photo 8.jpg";
import hindi_img9 from "../../assets/images/amis/hindi/Photo 9.jpg";
import hindi_img10 from "../../assets/images/amis/hindi/Photo 10.jpg";
import hindi_img11 from "../../assets/images/amis/hindi/Photo 11.jpg";
import hindi_img12 from "../../assets/images/amis/hindi/Photo 12.jpg";
import hindi_img13 from "../../assets/images/amis/hindi/Photo 13.jpg";
import hindi_img14 from "../../assets/images/amis/hindi/Photo 14.jpg";
import hindi_img15 from "../../assets/images/amis/hindi/Photo 15.jpg";
import hindi_img16 from "../../assets/images/amis/hindi/Photo 16.jpg";

import "../../styles/hero.css";

import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";

import React, { useRef, useState ,useEffect} from "react";
 
import axios from 'axios';
// Import Swiper React components

const Hero = () => {
  const onAutoplayTimeLeft = (s, time, progress) => {};
  const [newsData, setNewsData] = useState([]); // For news data
  const [events_data, setEventsData] = useState([]); // For events data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch news data
    const fetchData = async () => {
      try {
        const response = await fetch('https://aiims.developers13.org/api/newsList');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setNewsData(data.data); // Set the news data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Fetch events data
    const fetchEventsData = async () => {
      try {
        const response = await fetch('https://aiims.developers13.org/api/eventsList');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEventsData(data.data); // Set the events data
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchEventsData();
  }, []);


  // BANNER SECTION CODE
  const [images, setImages] = useState([]); // State for images
  //const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://aiims.developers13.org/api/bannersList'); // Replace with your actual API URL
        const data = await response.json();
        
        if (data.success) {
          setImages(data.data); // Assuming data.data is an array of image objects
        } else {
          console.error('Failed to retrieve data:', data.message);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []); // Empty dependency array means this runs once on mount
   // If loading, show a loader or message


// Famous people who inspires us! Section Start Here
const [images1, setImages1] = useState([]);
   useEffect(() => {
    const fetchinspires = async () => {
      try {
        const response = await fetch('https://aiims.developers13.org/api/inspiresList'); // Replace with your actual API URL
        const data = await response.json();
        
        if (data.success) {
          setImages1(data.data); // Assuming data.data is an array of image objects
        } else {
          console.error('Failed to retrieve data:', data.message);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchinspires();
  }, []); // Empty dependency array means this runs once on mount


  //  if (loading) {
  //   return <div>Loading...</div>;
  // }



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <div className="">


      <div className="banner_m1">
        <div className="plr-100 ">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h1 className="text-uppercase">AIMS explains awareness of their rights</h1>
              <h2>Laws safegaurd
                proceedings of government and courts under our constitution
                against violations for women and children.
                {/* AIMS Explain Women and Children Their Fundamental Rights and how
                to safe guard themselves */}
              </h2>
            </div>
            <div className="col-lg-6">
              
            <Carousel>
      {images.map((imageObj) => (
        <Carousel.Item key={imageObj.id}>
          <div className="card_m7">
            <img src={imageObj.image} alt={`Image ${imageObj.id}`} />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
 
            </div>
          </div>
        </div>
      </div>

      <div class="marquee_m1">
        <span>News</span>
        <marquee onmouseout="this.start();" onmouseover="this.stop();">
          <ul>
            <li>
            NHRC’s online human rights photography competition-2024 (Posted on : 23-08-2024)
            </li>
            <li>
            Engagement of Young Professionals as 'Legal Research Consultants' on a contract basis, in the National Human Rights Commission (Posted on : 02-08-2024)
            </li>
            <li>
            Minutes of meeting of the Core Group on Human Rights Defenders held on 19th July 2024 (Posted on : 02-08-2024)
            </li>
            <li>Educational visits of NHRC by Universities & Colleges (Posted on : 30-07-2024)</li>
            <li>Minutes of meeting of the Core Group on Business and Human Rights held on 11th July 2024 (Posted on : 30-07-2024)</li>
          </ul>
        </marquee>
      </div>

      <div className="bg_m4">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-6 mt-5">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-3 border">
              <Tabs defaultActiveKey="home" id="my-tabs">
  {/* NHRC in News Tab */}
  <Tab eventKey="home" title="NHRC in News">
    <div className="news_list">
      <div className="news_list list_m4 list_m4_sup1 pt-3">
        {newsData.length > 0 ? (
          newsData.map((newsSection, sectionIndex) =>
            newsSection.type === "NHRC in News" && newsSection.news.length > 0 ? (
              newsSection.news.map((newsItem, index) => (
                <li key={index} className="list_m15">
                  <a href="#!" key={index}>
                    <p className="px-2 c1 fw-semibold">{newsItem.date}</p>
                    <p className="px-2">{newsItem.description}</p>
                  </a>
                </li>
              ))
            ) : null
          )
        ) : (
          <p>No news available</p>
        )}
      </div>
    </div>
  </Tab>

  {/* NCW in News Tab */}
  <Tab eventKey="profile" title="NCW in News">
    <div className="news_list list_m4 list_m4_sup1 pt-3">
      {newsData.length > 0 ? (
        newsData.map((newsSection, sectionIndex) =>
          newsSection.type === "NCW in News" && newsSection.news.length > 0 ? (
            newsSection.news.map((newsItem, index) => (
              <li key={index} className="list_m15">
                <a href="#!" key={index}>
                  <p className="px-2 c1 fw-semibold">{newsItem.date}</p>
                  <p className="px-2">{newsItem.description}</p>
                </a>
              </li>
            ))
          ) : null
        )
      ) : (
        <p>No news available</p>
      )}
    </div>
  </Tab>
</Tabs>

              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-3 border">
                <Tabs defaultActiveKey="home" id="my-tabs1">
                  <Tab eventKey="home" title="AIMS Events">
                    {/* Section start here */}
                    <div class="news_list list_m4 list_m4_sup1 pt-3">
                    {events_data.length > 0 ? (
              events_data.map((event, index) => (
                <li key={index} className="list_m15">
                  <a href="#!">
                    <p className="px-2 c1 fw-semibold">{event.date}</p>
                    <p className="px-2">{event.description}</p>
                  </a>
                </li>
              ))
            ) : (
              <p>No events available</p>
            )}
                     
                    </div>
                    <div className="text-end">
                      {/* <button className="btn btn_m5 m-3">Read More</button> */}
                    </div>
                    {/* Section End here */}
                  </Tab>
                  {/* <Tab eventKey="profile" title="NCW in News">
        <p>This is the Profile tab content.</p>
      </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className=" pt-md-5 pb-md-5">
        <div className="container">
          <div className="row  mb-5">
            <div className="col-md-6 ">
              <img
                src={vision_mission_banner}
                className="img-fluid rounded-3"
                alt=""
              />
            </div>
            <div className="col-md-6 align-self-center">
              <h1 class="block__title fw-bold">Our Vision & Mission</h1>
              <p className="font_18">
                To spread mass awareness among the common down trodden people of
                Telangana and Andhra Pradesh in their local language and by
                adopting their culture and through it, letting them know of
                their rights and to defend themselves.
              </p>
              <p className="font_18">
                {" "}
                To project these rights against violation we are using the
                following processes to make them easily understand the subject
                of their rights granted under the Constitution of India, to
                these women and children in particular. AIMS is using projection
                through pamphlets, series of paintings related to atrocities
                against women and children, conducting of camps, exhibitions and
                involving prominent citizen of society.
              </p>
              <p className="font_18">
                This is creating an impact among these people of the society,
                awareness is taking shape, and progress is being appreciated
                which is giving us immense satisfaction. AIM's wants to in
                course of time take this mission, and spread the same to
                Villages, Mandals, and Districts of Telangana and Andhra
                Pradesh. AIMS explain their Fundamental Rights and how to
                safeguard themselves (women children), which is clearly
                described by our founding Fathers of our Constitution as they
                envisaged.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_m4">
        <div className="container pt-5 pb-5 tabs_m1">
          <div className="row testimonial__body">
            <div className="col-md-8 offset-md-2  text-center pb-3">
              <h1 class="block__title fw-bold mb-3">Featured Campaigns</h1>
              <p className="font_24">
                Discover the power of giving - explore our featured campaigns
                and join a community of changemakers, making a difference for
                causes that matter, one donation at a time.
              </p>
            </div>
            <div className="col-sm-12 ">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-2 px-3 px-md-5">
                <Tabs defaultActiveKey="english_paints" id="my-tabs1">
                  <Tab eventKey="english_paints" title="English">
                  <div className="mt-3">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                      }}
                 >
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint5} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint6} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint6} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint7} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint9} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint10} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint11} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint12} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint13} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint14} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint15} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint16} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint17} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint18} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint19} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint20} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint21} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint22} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint23} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint24} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint25} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint26} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint27} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint28} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint29} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint30} />
                        </div>
                      </SwiperSlide>
 
                    </Swiper>
                    <div class="text-end pt-3 pb-2">
                      <a class="btn btn_m5 btn_m5_small" href="/english-art-gallery">
                        View More
                      </a>
                    </div>
                    </div>
                  </Tab>
                  <Tab eventKey="hindi_paints" title="Hindi">
                  <div className="mt-3">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                      }}
                 >
                      {/* <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img1} />
                        </div>
                      </SwiperSlide> */}
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img5} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img6} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img7} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img8} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img9} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img10} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img11} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img12} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img13} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img14} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img15} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={hindi_img16} />
                        </div>
                      </SwiperSlide>
                    
                    </Swiper>
                    <div class="text-end pt-3 pb-2">
                      <a class="btn btn_m5 btn_m5_small" href="/hindi-art-gallery">
                        View More
                      </a>
                    </div>
                    </div>
                  </Tab>
                  <Tab eventKey="telugu_paints" title="Telugu">
                 <div className="mt-3">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                      }}
                 >
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child1} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child2} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child3} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child5} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens5} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div class="text-end pt-3 pb-2">
                      <a class="btn btn_m5 btn_m5_small" href="/telugu-art-gallery">
                        View More
                      </a>
                    </div>
                    </div>
                  </Tab>
                 
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
