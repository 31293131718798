import React from "react";
import jai_sir1 from "../assets/images/jai_sir1.png";
import team_num1 from "../assets/images/ramesh_sajjan.png";
import tems_banner from "../assets/images/team_banner.png";
import team_banner1 from "../assets/images/team_banner1.png";
const Team = () => {
     return (
         <div>

<div className=" ">
<div className="sub_level_banner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-4  offset-md-1">
                <div className="about__banner-text">
                  <h1 className="block__title">Team</h1>
                  <h5>
                  AIMS explain their Fundamental Rights and how to safeguard themselves (women and children)
                  </h5>
                </div>
              </div>
              <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
                <div className=" sub_pages_banner">
                  <img
                    src={team_banner1}
                    alt="About Banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
           
           </div>


           <div className="banner_m3 d-none">
           <div className="container py-5">
             <div className="row align-items-center">
                 <div className="col-md-6 offset-sm-3">
                 <div className="banner3_content">
                      <h3 className="fweight_600">Our Team</h3>
                     <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    
                    </div>
                </div>
                {/* <div className="col-md-5 offset-sm-1">
                    <div className="about__banner-img">
                        <img src={tems_banner} alt="About Banner" className="w-100"/>
                    </div>
                </div> */}
             </div>
           </div>
           </div>
<div className="shape_m3 d-none">


<div className="container">
<div className="row mt-5 mb-5 pt-5" >
        <div className="col-sm-6 text-center">
           <img src={jai_sir1} alt="Why U-Saver Image" className="img-fluid"/>
        </div>
            <div className="col-sm-6 text-left">
<div className="basic__header mb-2">
                    <h1 className="block__title">AIMS HUMAN</h1>
                    <div className="list_m9">
                  
                    </div>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <p className="mt-5">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <p className="mt-5">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
               
                </div>
                </div>
</div>
</div> 
<div className="bg_m4">
           <div className="container pt-5 pb-5">
       

                <div className="row">
                  <div className="col-sm-8 offset-sm-2 text-center pb-5">
                  <h1 class="block__title fw-bold mb-3">Core Team</h1>
                  <p class="font_24">Till now we are conducted 14 exhibitions with own assembled funds and a small donation to meet partial expenditure by donors. </p>
                  </div>
                 
                </div>
                <div className="row">
                <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">Dr. Madhava Vasu</p>
                    <p className=" m-0">President</p>
                  </div>
                  </div>
                  </div>

                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">M. Madhusudan Reddy</p>
                    <p className=" m-0">Vice President</p>
                  </div>
                  </div>
                  </div>


                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">P. Bal Reddy</p>
                    <p className=" m-0">Secretary</p>
                  </div>
                  </div>
                  </div>

                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">Ratanakar Kilaru</p>
                    <p className=" m-0">Treasurer</p>
                  </div>
                  </div>
                  </div>


                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">Sagi Chaitanya Sharama</p>
                    <p className=" m-0">Member</p>
                  </div>
                  </div>
                  </div>


                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">S. Padama</p>
                    <p className=" m-0">Member</p>
                  </div>
                  </div>
                  </div>


                  <div className="col-sm-3 mb-5">
                  <div className="team_member">
                  {/* <img src={team_num1} alt="team_num1" className="img-fluid w-100"/> */}
                  <div className="name_desi">
                    <p className="fw-bolder m-0">Pannala Padmanabha Sastry</p>
                    <p className=" m-0">Member</p>
                  </div>
                  </div>
                  </div>

                 
                </div>
<div>
 
</div>


                <div className="row d-none">
                  <div className="col-sm-6 offset-sm-3 text-center pb-5">
                  <h1 class="block__title mb-3">Event <span class="highlight">Gallery</span></h1>
                  <p class="block__paragraph mb-0 color_1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div>
                 
                </div>


                <div className="row d-none">
                  <div className="col-sm-2 offset-sm-1 align-self-center">
                    <div className="gallery_item ">
                    <img src={team_num1} alt="team_num1" className="img-fluid w-100  mb-4"/>
                    </div>
                    </div>
                    <div className="col-sm-2 align-self-center">
                    <div className="gallery_item">
                    <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                   
                    </div> 
                    <div className="gallery_item">
                    <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                   
                    </div>
                    </div>
                    <div className="col-sm-4  align-self-cente">
                       <div className="row">
                        <div className="col-sm-5">
                        <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       <div className="col-sm-5">
                       <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       <div className="col-sm-8">
                          <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       <div className="col-sm-4">
                          <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       <div className="col-sm-5">
                          <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       <div className="col-sm-5">
                          <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                       </div>
                       </div>
                      </div>
                     
                      
                      <div className="col-sm-2  align-self-center">
                      <div className="gallery_item">
                        <img src={team_num1} alt="team_num1" className="img-fluid w-100 mb-4"/>
                        </div>
                      </div>
                    </div>

                </div>
                <hr className="m-0"></hr>
                </div>
                </div>
        
    )
};

export default Team;