//import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import "../../styles/testimonials.css";

import React, { useRef, useState ,useEffect} from "react";
 
import axios from 'axios';


import narges_mohammadi from "../../assets/images/narges_mohammadi.jpg"
import mother_theresa from "../../assets/images/mother_theresa.jpg"
import maria_ressa from "../../assets/images/maria-ressa.jpg";
import Abraham_Lincoln from "../../assets/images/Abraham_Lincoln.jpg";
import aung from "../../assets/images/aung.jpg";
import inspiring_1 from "../../assets/images/inspiring_1.jpg";
import inspiring_2 from "../../assets/images/inspiring_2.jpg";
import inspiring_3 from "../../assets/images/inspiring_3.jpg";
import inspiring_4 from "../../assets/images/inspiring_4.jpg";
import inspiring_5 from "../../assets/images/inspiring_5.jpg";
import inspiring_6 from "../../assets/images/inspiring_6.jpg";
import inspiring_7 from "../../assets/images/inspiring_7.jpg";
import inspiring_8 from "../../assets/images/inspiring_8.jpg";
import inspiring_9 from "../../assets/images/inspiring_9.jpg";
import inspiring_10 from "../../assets/images/inspiring_10.jpg";
import kailash_satyarthi from "../../assets/images/kailash_satyarthi.jpg";


const Testimonials = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

// Famous people who inspires us! Section Start Here
const [images1, setImages1] = useState([]);
useEffect(() => {
 const fetchinspires = async () => {
   try {
     const response = await fetch('https://aiims.developers13.org/api/inspiresList'); // Replace with your actual API URL
     const data = await response.json();
     
     if (data.success) {
       setImages1(data.data); // Assuming data.data is an array of image objects
     } else {
       console.error('Failed to retrieve data:', data.message);
     }
   } catch (error) {
     console.error("Error fetching images:", error);
   } finally {
     setLoading(false);
   }
 };

 fetchinspires();
}, []); // Empty dependency array means this runs once on mount


    return (
        <div className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 text-center ">
                        <h1 className="block__title fw-bold mb-3">We Follow</h1>
                        <p className="font_24">Famous people who inspires us!</p>
                    </div>
                </div>
                <div className="row testimonial__body">
                    <div className="col-sm-12 p-0">
                    

                    <Swiper 
                    navigation={true}
                     modules={[Navigation]} 
                     slidesPerView={1}
                     breakpoints={{
                        320: {
                         slidesPerView: 1,
                       },
                       480: {
                         slidesPerView: 2,
                        },
                      767: {
                         slidesPerView: 3,
                        },
                       991: {
                         slidesPerView: 5,
                       }
                     }}
                     className="mySwiper"  >

{/* 
{images1.map((imageObj1) => (
        <div key={imageObj1.id}>
          <div className="card_m7">
            <img src={imageObj1.image} alt={`Image ${imageObj1.id}`} />
            <p>{imageObj1.name}</p>
          </div>
        </div>
      ))}

                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_1} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Mother Theresa</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_2} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Ellen Johnson Sirleaf</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_3} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Jody Williams</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_4} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Leymah Gbowee</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_5} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Maathai</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_6} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Malala Yousafzai</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_7} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Maria Ressa</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_8} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Narges Mohammadi</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_9} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Shirin Ebadi</p>
                           </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={inspiring_10} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Tawakkol Karman</p>
                           </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                         <div className="card_m4">
                            <img src={kailash_satyarthi} className="w-100" alt=""/>
                           <div className="p-2">
                            <p className="font_16 text-center m-0">Kailash Satyarthi</p>
                           </div>
                        </div>
                    </SwiperSlide> */}
                  
                 
                  {images1.map((imageObj1) => (
  <SwiperSlide key={imageObj1.id}>
    <div className="card_m4">
      <img src={imageObj1.image} className="w-100" alt={`Image ${imageObj1.id}`} />
      <div className="p-2">
        <p className="font_16 text-center m-0">{imageObj1.name}</p>
      </div>
    </div>
  </SwiperSlide>
))}

                </Swiper>
                </div>
                </div>
            </div>
        </div>
    )
};

export default Testimonials;
 