import React from "react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images7, images8, images9 } from "./paintimages";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import event23 from "../assets/images/amis/events/event1/event23.JPG";
const Paintshindi = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  //  GALLERY 7 SET START HERE
  const [windowWidth7, setWindowWidth7] = useState(window.innerWidth);
  const [index7, setIndex7] = useState(-1);
  const currentImage7 = images7[index7];
  const nextIndex7 = (index7 + 1) % images7.length;
  const nextImage7 = images7[nextIndex7] || currentImage7;
  const prevIndex7 = (index7 + images7.length - 1) % images7.length;
  const prevImage7 = images7[prevIndex7] || currentImage7;
  const handleClick7 = (index7, item) => {
    setIndex7(index7);
    setTimeout(() => {
      setWindowWidth7((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose7 = () => setIndex7(-1);
  const handleMovePrev7 = () => setIndex7(prevIndex7);
  const handleMoveNext7 = () => setIndex7(nextIndex7);
  //  GALLERY 7 SET END HERE

  //  GALLERY 8 SET START HERE
  const [windowWidth8, setWindowWidth8] = useState(window.innerWidth);
  const [index8, setIndex8] = useState(-1);
  const currentImage8 = images8[index8];
  const nextIndex8 = (index8 + 1) % images8.length;
  const nextImage8 = images8[nextIndex8] || currentImage8;
  const prevIndex8 = (index8 + images8.length - 1) % images8.length;
  const prevImage8 = images8[prevIndex8] || currentImage8;
  const handleClick8 = (index8, item) => {
    setIndex8(index8);
    setTimeout(() => {
      setWindowWidth8((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose8 = () => setIndex8(-1);
  const handleMovePrev8 = () => setIndex8(prevIndex8);
  const handleMoveNext8 = () => setIndex8(nextIndex8);
  //  GALLERY 8 SET END HERE

  //  GALLERY 9 SET START HERE
  const [windowWidth9, setWindowWidth9] = useState(window.innerWidth);
  const [index9, setIndex9] = useState(-1);
  const currentImage9 = images9[index9];
  const nextIndex9 = (index9 + 1) % images9.length;
  const nextImage9 = images9[nextIndex9] || currentImage9;
  const prevIndex9 = (index9 + images9.length - 1) % images9.length;
  const prevImage9 = images9[prevIndex9] || currentImage9;
  const handleClick9 = (index9, item) => {
    setIndex9(index9);
    setTimeout(() => {
      setWindowWidth9((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose9 = () => setIndex9(-1);
  const handleMovePrev9 = () => setIndex9(prevIndex9);
  const handleMoveNext9 = () => setIndex9(nextIndex9);
  //  GALLERY 9 SET END HERE

  return (
    <div className="Paints">
       <div className="sub_level_banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4  offset-md-1">
              <div className="about__banner-text">
                <h1 className="block__title">Art Gallery</h1>
                <h5>
                AMIS HUMAN RIGHTS SOCIETY Art GALLERY DURING THE YEAR 2009 TO 2024
                </h5>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
              <div className=" sub_pages_banner">
                <img
                  src={event23}
                  alt="About Banner"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_m4">
        <div className="container pt-5">
          {/* <div className="row pt-2 mb-0 pb-4">
            <div className="col-md-8 offset-md-2 text-center block__header mb-0">
              <h1 className="block__title mb-1 position-relative">
                OUR PAINTS
              </h1>
              <p className="block__paragraph mb-0 color_1">
                Aims Human Rights Society Paints during the year 2009 to 2024
              </p>
            </div>
          </div> */}

          <div className="tabs_m4">
            <Tabs activeTab={activeTab} onTabClick={onTabClick}>
              <Tab key="Tab1">Childrens</Tab>
              <Tab key="Tab2">Womens</Tab>
              <Tab key="Tab3">Society</Tab>
            </Tabs>

            <TabScreen
              key="Tab1"
              activeTab={activeTab}
              index={0}
              className="some-animation-class"
            >
              <Gallery
                images={images7}
                onClick={handleClick7}
                enableImageSelection={false}
              />
              {!!currentImage7 && (
                <Lightbox
                  mainSrc={currentImage7.original}
                  imageTitle={currentImage7.caption}
                  mainSrcThumbnail={currentImage7.src}
                  nextSrc={nextImage7.original}
                  nextSrcThumbnail={nextImage7.src}
                  prevSrc={prevImage7.original}
                  prevSrcThumbnail={prevImage7.src}
                  onCloseRequest={handleClose7}
                  onMovePrevRequest={handleMovePrev7}
                  onMoveNextRequest={handleMoveNext7}
                />
              )}
            </TabScreen>
            <TabScreen
              key="Tab2"
              activeTab={activeTab}
              index={1}
              className="some-animation-class"
            >
              <Gallery
                images={images8}
                onClick={handleClick8}
                enableImageSelection={false}
              />
              {!!currentImage8 && (
                <Lightbox
                  mainSrc={currentImage8.original}
                  imageTitle={currentImage8.caption}
                  mainSrcThumbnail={currentImage8.src}
                  nextSrc={nextImage8.original}
                  nextSrcThumbnail={nextImage8.src}
                  prevSrc={prevImage8.original}
                  prevSrcThumbnail={prevImage8.src}
                  onCloseRequest={handleClose8}
                  onMovePrevRequest={handleMovePrev8}
                  onMoveNextRequest={handleMoveNext8}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab3"
              activeTab={activeTab}
              index={2}
              className="some-animation-class"
            >
              <Gallery
                images={images9}
                onClick={handleClick9}
                enableImageSelection={false}
              />
              {!!currentImage9 && (
                <Lightbox
                  mainSrc={currentImage9.original}
                  imageTitle={currentImage9.caption}
                  mainSrcThumbnail={currentImage9.src}
                  nextSrc={nextImage9.original}
                  nextSrcThumbnail={nextImage9.src}
                  prevSrc={prevImage9.original}
                  prevSrcThumbnail={prevImage9.src}
                  onCloseRequest={handleClose9}
                  onMovePrevRequest={handleMovePrev9}
                  onMoveNextRequest={handleMoveNext9}
                />
              )}
            </TabScreen>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paintshindi;
